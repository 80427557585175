import type { InputElementProps as _InputElementProps } from "@chakra-ui/react";
import {
    forwardRef,
    InputLeftElement as _InputLeftElement,
    InputRightElement as _InputRightElement,
} from "@chakra-ui/react";

export interface InputElementProps extends _InputElementProps {}

export const InputLeftElement = forwardRef<InputElementProps, "div">(({ ...props }, ref) => (
    <_InputLeftElement ref={ref} {...props} />
));

// This is used in `input-group.tsx`
InputLeftElement.id = "InputLeftElement";

export const InputRightElement = forwardRef<InputElementProps, "div">(({ ...props }, ref) => (
    <_InputRightElement ref={ref} {...props} />
));

// This is used in `input-group.tsx`
InputRightElement.id = "InputRightElement";
