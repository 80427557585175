import { useState } from "react";
import { ECCOIcon } from "@ui/components/media-and-icons/ecco-icon/ECCOIcon";
import { InputGroup } from "@ui/components/forms/input/InputGroup";
import { InputRightElement } from "@ui/components/forms/input/InputElement";
import { Input } from "@ui/components/forms/input/Input";
import { IconButton } from "@ui/components/forms/icon-button/IconButton";
import { Button } from "@ui/components/forms/button/Button";
import { Field } from "@ui/components/forms/field/Field";

const PasswordField = ({
    id = "password",
    ariaLabelledBy,
}: {
    id: string;
    ariaLabelledBy: string;
}) => {
    const [isHidden, setIsHidden] = useState(true);
    const inputType = isHidden ? "password" : "text";
    //TODO: intl - aria label problems
    return (
        <InputGroup data-privacy="true">
            <Field
                as={Input}
                id={id}
                name={id}
                aria-labelledby={ariaLabelledBy}
                type={inputType}
                autoComplete="nope"
                sx={{
                    "::-ms-reveal": {
                        display: "none",
                    },
                }}
            />
            <InputRightElement>
                <IconButton
                    as={Button}
                    aria-label={isHidden ? "Hide password" : "Show password"}
                    bg={"transparent !important"}
                    color={"black"}
                    variant={"tertiary"}
                    icon={<ECCOIcon name={isHidden ? "passwordhide" : "passwordshow"} />}
                    size={"sm"}
                    display={"flex"}
                    _hover={{ color: "black", bg: "transparent" }}
                    onClick={() => setIsHidden(!isHidden)}
                />
            </InputRightElement>
        </InputGroup>
    );
};

export default PasswordField;
