import { CommonCMS } from "@lib/constants/contentful";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { Text } from "@ui/components/content/text/Text";
import { Grid, GridItem } from "@ui/components/layout/grid/Grid";
import type { FC, ReactNode } from "react";

interface Props {
    titleKey: string;
    children: ReactNode;
}

const AuthView: FC<Props> = ({ titleKey, children }) => {
    const { get: getMicrocopy } = useMicrocopy();
    const title = getMicrocopy(CommonCMS.global, CommonCMS[titleKey]);

    return (
        <Grid
            gridTemplateColumns={["100%", "100%", "100%", "10fr 8fr"]}
            sx={{
                bg: "white",
                mt: [2, 2, 2, 0],
                py: 6,
                px: [3, 4, 4, 6],
            }}
        >
            <GridItem>
                <Text
                    fontSize={["mobileHeading3", "desktopHeading5"]}
                    fontWeight={"semibold"}
                    mb={6}
                    textTransform="uppercase"
                >
                    {title}
                </Text>
                {children}
            </GridItem>
        </Grid>
    );
};

export default AuthView;
