import type { HeadingProps as _HeadingProps } from "@chakra-ui/react";
import { forwardRef } from "@chakra-ui/react";
import { Heading as _Heading } from "@chakra-ui/layout";
export interface HeadingProps extends _HeadingProps {
    text?: string;
}

export const Heading = forwardRef<HeadingProps, "h2">(({ ...props }, ref) => {
    return <_Heading ref={ref} {...props} />;
});
